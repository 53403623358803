import { jsx as _jsx } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import IconWithText from '@shared/ui/display/IconWIthText';
import Button from '@shared/ui/buttons/Button';
import { StepContainer } from '@widgets/sidebars/shared';
import { CheckmarkIcon, } from '../shared';
const AddPhotoConfirm = ({ goToNextStep }) => {
    const { t } = useTranslation();
    const handleClick = () => {
        if (goToNextStep) {
            goToNextStep();
        }
    };
    return (_jsx(StepContainer, { content: (_jsx(IconWithText, { IconElement: _jsx(CheckmarkIcon, {}), text: t('profile.create_trader.steps.add_photo_success.text', { ns: 'common' }) })), buttons: (_jsx(Button, { variant: 'filled', color: 'primary', size: 'large', fullWidth: true, onClick: handleClick, children: t('profile.create_trader.steps.add_photo_success.btn_text', { ns: 'common' }) })) }));
};
export default AddPhotoConfirm;
