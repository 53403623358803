import { jsx as _jsx } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import { ReactComponent as ImageIcon } from '@icons/wolfkit-light/image-light.svg';
import FileInput from '@shared/ui/inputs/FileInput';
import { StepContainer, Title } from '@widgets/sidebars/shared';
import Icon from '@shared/ui/icons/Icon';
import { getFileInputErrors } from '@features/profile/profile-edit';
const AddPhoto = ({ goToNextStep }) => {
    const { t } = useTranslation();
    const handleChange = (filesList) => {
        goToNextStep({ initialImage: filesList[0] });
    };
    const fileInputErrors = useMemo(() => getFileInputErrors(t), [t]);
    return (_jsx(StepContainer, { title: (_jsx(Title, { children: t('profile.create_trader.steps.add_photo.title', { ns: 'common' }) })), content: (_jsx(FileInput, { innerLabelText: t('profile.create_trader.steps.add_photo.file_input.title', { ns: 'common' }), description: t('profile.create_trader.steps.add_photo.file_input.description', { ns: 'common' }), onChange: handleChange, maxSize: 5000000, errors: fileInputErrors, icon: (_jsx(Icon, { IconComponent: ImageIcon, keepOriginalColor: true, size: 30 })), mimeType: ['image/gif', 'image/jpeg', 'image/png'] })) }));
};
export default AddPhoto;
